import {strToObj, jsonToStr, str2char, queryString} from './utilities/string.js';
import {browser} from './utilities/browser.js';
import {cookies} from './utilities/cookies.js';
import {dataStorage} from './utilities/dataStorage.js';
import {dateFormat, nowTimeStamp} from './utilities/date.js';
import {getPubtime} from './utilities/pubtime.js';
import {numRound, isInteger, zeroPadding, getNumberByTenThousand} from './utilities/number.js';
import {dom} from './utilities/dom.js';
import {ajax} from './utilities/ajax.js';
import {require, loadCss} from './utilities/require.js';
import {platform} from './utilities/platform.js';
import {initSentry} from './utilities/sentry.js';

import $ from 'jquery';

window.$ = $;
window.jQuery = $;

initSentry();

window.util = {
    strToObj: strToObj,
    jsonToStr: jsonToStr,
    str2char: str2char,
    queryString: queryString,
    browser: browser,
    cookies: cookies,
    dataStorage: dataStorage,
    dateFormat: dateFormat,
    nowTimeStamp: nowTimeStamp,
    getPubtime: getPubtime,
    numRound: numRound,
    getNumberByTenThousand: getNumberByTenThousand,
    isInteger: isInteger,
    zeroPadding: zeroPadding,
    dom: dom,
    ajax: ajax,
    platform: platform,
    require: require,
    loadCss: loadCss
};
